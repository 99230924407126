import { ElectionPageParams, ElectionTime } from "@/src/common/types"
import { raceHasElectionInTime, raceIsRepresentative } from "@/src/common/utils"
import PresidentElectionCtx from "@/src/contexts/PresidentElectionCtx"
import {
  RACE_TO_URL_SLUG_HASH_MAP,
  URL_SLUG_TO_RACE_HASH_MAP
} from "@/src/elections/business-layer/constants"
import { parseRawTimelineData } from "@/src/elections/business-layer/selectors"
import {
  BaseElectionTemplateProps,
  Race
} from "@/src/elections/business-layer/types"
import { GetElectionPropsUsecase } from "@/src/elections/business-layer/usecases"
import ElectionTemplate from "@/src/elections/components/ElectionTemplate"
import {
  Data,
  ElectionsAPIInstance,
  ExternalHTTPInstance
} from "@/src/elections/data-layer"
import { ElectionSources2023 } from "@/src/elections/data-layer/types"
import type {
  GetStaticPropsContext,
  GetStaticPropsResult,
  NextPage
} from "next"
import { useState } from "react"

const RaceSummary: NextPage<BaseElectionTemplateProps> = (
  props: BaseElectionTemplateProps
) => {
  const [electionSource, setElectionSource] = useState(ElectionSources2023.TV)

  return (
    <PresidentElectionCtx.Provider
      value={{ source: electionSource, setSource: setElectionSource }}
    >
      <ElectionTemplate
        templateProps={props}
        electionsAPI={ElectionsAPIInstance}
        externalHTTP={ExternalHTTPInstance}
      />
    </PresidentElectionCtx.Provider>
  )
}

export async function getStaticPaths() {
  const timelines = await ElectionsAPIInstance.fetchElectionsDataTimeline()
  return {
    paths: Data.upcomingElections.reduce(
      (electionsYearRace, election) => [
        ...electionsYearRace,
        ...parseRawTimelineData(timelines)
          .map((time: ElectionTime) => {
            if (time.races?.state_house) delete time.races.state_house
            return time
          })
          .filter((time) => raceHasElectionInTime(time, election.slug as Race))
          .map((timeline) => {
            return {
              params: {
                year: timeline.year,
                raceUrlSlug: RACE_TO_URL_SLUG_HASH_MAP[election.slug as Race]
              }
            }
          })
      ],
      [] as Array<{
        params: {
          year: string
          raceUrlSlug: string
        }
      }>
    ),
    fallback: false
  }
}

export async function getStaticProps(
  context: GetStaticPropsContext<ElectionPageParams>
): Promise<GetStaticPropsResult<BaseElectionTemplateProps>> {
  const raceUrlSlug = context.params
    ?.raceUrlSlug as keyof typeof URL_SLUG_TO_RACE_HASH_MAP
  const race = URL_SLUG_TO_RACE_HASH_MAP[raceUrlSlug]

  const getElectionPropsUsecase = new GetElectionPropsUsecase()
  const props = await getElectionPropsUsecase.execute(
    raceUrlSlug,
    context.params?.state,
    raceIsRepresentative(race) ? "constituency_code" : "state_code",
    context.params?.year
  )

  if (props.electionIndex === -1) {
    return {
      notFound: true
    }
  }

  return {
    revalidate: false,
    props
  }
}

export default RaceSummary
